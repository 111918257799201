<template>
  <div>
    <div>
      <div>
        <el-row
          v-if="canSetting"
          type="flex"
          align="middle"
          style="padding-bottom: 10px; padding-top: 10px;"
        >
          <div>互动评论设置：</div>
          <el-radio-group v-model="commentTypeInt" @change="onChangeCommentType">
            <el-radio :label="0">仅展示精选评论</el-radio>
            <el-radio :label="1">展示所有评论</el-radio>
          </el-radio-group>
        </el-row>
        <scene-comment-item
          v-for="item in commentList"
          :key="item.commentId"
          :item="item"
          :scene-id="sceneId"
          :scene-type="sceneType"
          :can-setting="canSetting"
          @onShield="onShieldComment(item.commentId, !item.status)"
          @onSetTop="onTopComment(item.commentId, !item.topCommentStatus)"
          @onSetActive="onActiveComment(item.commentId, !item.selectiveCommentStatus)"
        />
        <Empty v-if="showEmpty" text="暂无评论" />
        <el-row type="flex" justify="end" class="mgn-t10">
          <el-pagination
            :current-page="pageNo"
            :page-size="pageSize"
            background
            hide-on-single-page
            :total="commentTotalCount"
            @current-change="handleCurrentChange"
          />
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SceneCommentItem from '../child/SceneCommentItem'
import Empty from '../tim/components/empty'
export default {
  name: 'QuestionnaireDetail',
  components: {
    Empty,
    SceneCommentItem
  },
  props: {
    sceneId: {
      type: String,
      default: ''
    },
    sceneType: {
      type: Number,
      default: 14
    },
    commentSettingId: {
      type: String,
      default: ''
    },
    commentType: {
      type: Number,
      default: 0
    },
    canSetting: {   // 是创建者才可以设置
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      commentList: [],
      pageNo: 1,
      pageSize: 100,
      commentTotalCount: 0,
      showEmpty: false,
      commentTypeInt: 1
    }
  },
  mounted() {
    this.commentTypeInt = this.commentType
    this.initComment()
  },
  methods: {
    ...mapActions(['getSceneCommentList', 'onShieldSceneComment', 'onSetTopComment', 'onSetActiveComment', 'setCommentShowType']),
    onChangeCommentType() {
      this.setCommentShowType({ sceneId: this.sceneId, sceneType: this.sceneType, ruleType: this.commentTypeInt, commentSettingId: this.commentSettingId }).catch(rea => {
        this.$message.error(rea)
      })
    },
    onShieldComment(commentId, isOpen) {
      this.onShieldSceneComment({ commentId, isOpen, sceneId: this.sceneId, sceneType: this.sceneType }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, status: isOpen }
          }
          return v
        })
      })
    },
    onTopComment(commentId, isTop) {
      this.onSetTopComment({ commentId, isTop, sceneId: this.sceneId, sceneType: this.sceneType }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, topCommentStatus: isTop }
          }
          return v
        })
      })
    },
    onActiveComment(commentId, isSelective) {
      this.onSetActiveComment({ commentId, isSelective, sceneId: this.sceneId, sceneType: this.sceneType }).then(() => {
        this.commentList = [...this.commentList].map(v => {
          if (v.commentId === commentId) {
            v = { ...v, selectiveCommentStatus: isSelective }
          }
          return v
        })
      })
    },
    handleCurrentChange(page) {
      this.pageNo = page
      this.initComment()
    },
    initComment() {
      const opt = {
        pageNo: this.pageNo,
        sceneId: this.sceneId,
        sceneType: this.sceneType
      }
      this.getSceneCommentList(opt).then(
        res => {
          if (!res.data.length) {
            this.showEmpty = true
          }
          this.commentList = res.data
          this.commentTotalCount = res.count
        },
        rea => {
          this.$message.error(rea)
        }
      )
    }
  }
}
</script>

<!-- <style lang='scss' scoped>

</style> -->
