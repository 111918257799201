<template>
  <div class="">
    <div class="title-content">
      <div class="title-line-box">
        <div class="mgn-b5">
          <el-select
            v-model="activityQuestType"
            @change="getEntrustList"
          >
            <el-option
              label="委托调研"
              :value="1"
            />
            <el-option
              label="病例实战"
              :value="2"
            />
          </el-select>
          <el-select
            v-model="activityQuestId"
            @change="onGetDetail"
          >
            <el-option
              v-for="item in entrustList"
              :key="item.activityQuestId"
              :label="item.title"
              :value="item.activityQuestId"
            />
          </el-select>
        </div>
        <div v-if="activityQuestId" class="data-analysis-box">
          <el-button
            v-if="activityQuestType === 2"
            type="primary"
            plain
            @click="onPreView"
          >
            预览
          </el-button>
          <el-button
            v-if="activityQuestType === 2"
            type="primary"
            plain
            @click="onCheckComment"
          >
            评论互动
          </el-button>
          <el-button type="primary" @click="onDataAnalysis">数据分析</el-button>
        </div>
      </div>
      <div v-if="detail.intro" class="mgn-b5">委托调研简介：{{ detail.intro }}</div>
      <div class="mgn-b5">题目设置：{{ detail.examType === 0? '分对错': '不分对错' }}</div>
      <div v-if="detail.answerType === 1">答题方式：{{ `${'共' + examList.length + '题，答题时随机展示' + detail.randomExamCount + '题'}` }}</div>
      <div v-else>答题方式：{{ `${'共' + examList.length + '题，答题时按顺序展示'}` }}</div>
      <div v-if="activityQuestType === 2 && activityQuestId" class="case-practice-box">
        <div class="mgn-b5">
          <span class="case-label">病例ID：</span>
          <span>{{ detail.caseNumber || '-' }}</span>
        </div>
        <div class="mgn-b5">
          <span class="case-label">患者年龄：</span>
          <span>{{ detail.age || '-' }}</span>
        </div>
        <div class="mgn-b5">
          <span class="case-label">患者性别：</span>
          <span>{{ detail.gender === 1 ? '男' : (detail.gender === 2 ? '女' : '其它') }}</span>
        </div>
        <div class="mgn-b5">
          <span class="case-label">就诊日期：</span>
          <span>{{ detail.visitDate || '-' }}</span>
        </div>
        <div class="mgn-b5">
          <span class="case-label">就诊科室：</span>
          <span>{{ detail.oneLevelPlatformDepartmentName || '-' }}</span>
          <span v-if="detail.twoLevelPlatformDepartmentName"> / {{ detail.twoLevelPlatformDepartmentName }}</span>
        </div>
        <div class="mgn-b5">
          <span class="case-label">病人主诉：</span>
          <span>{{ detail.chiefComplaint || '-' }}</span>
        </div>
      </div>
    </div>
    <div class="exam-content">
      <div v-if="!examList.length" class="scene-empty">
        <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
        暂无数据，请切换委托调研或病例实战
      </div>
      <exam-item
        v-for="(item, index) in examList"
        :key="item.examId"
        :item="item"
        :index="index + 1"
        :activity-quest-id="activityQuestId"
        :is-show-a-b-c="activityQuestType !== 2"
      />
    </div>
    <el-dialog
      title="互动评论"
      :visible.sync="isShowCommentList"
      width="50%"
      center
    >
      <div class="content-box">
        <!--病例实战的activityQuestId 也是sceneId -->
        <CommentList
          v-if="isShowCommentList"
          :can-setting="canSetting"
          :scene-type="sceneTypeInt"
          :scene-id="activityQuestId"
          :comment-setting-id="detail.commentSettingId"
          :comment-type="detail.commentType"
        />
      </div>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button type="primary" @click="isShowCommentList = false">关闭</el-button>
      </div>
    </el-dialog>
    <CommonPreviewModal
      v-model="showPreviewModal"
      :preview-url="previewUrl"
    />
  </div>
</template>

<script>
import ExamItem from '@/bizComponents/ExamItem'
import CommentList from './CommentList'
import domainRoute from '@/utils/domain-route'
import CommonPreviewModal from '@/bizComponents/CommonPreviewModal.vue'

export default {
  name: 'ExamListDetail',
  components: {
    ExamItem,
    CommentList,
    CommonPreviewModal
  },
  props: {
    sceneId: {
      type: String,
      default: ''
    },
    sceneTypeStr: {
      type: String,
      default: 'meeting'    // 科会、播客：meeting；直播、会议：live
    },
    sceneType: {
      type: Number,
      default: 14
    }
  },
  data() {
    return {
      showPreviewModal: false,
      previewUrl: '',
      isShowCommentList: false,
      canSetting: false,
      activityQuestType: 1,
      // 病例实战 activityQuestId 也是sceneId
      activityQuestId: '',
      entrustList: [],
      detail: {},
      examList: []
    }
  },
  computed: {
    sceneTypeInt() {
      return this.activityQuestType === 2 ? 14 : this.sceneType
    }
  },
  mounted() {
    this.getEntrustList()
    this.canSetting = this.$route.query.isCreator == 'true'
  },
  methods: {
    onPreView() {
      this.$axios.get(this.$API.getPreViewToken).then(res => {
        this.previewUrl = domainRoute.casePracticePreview(this.activityQuestId, this.sceneTypeStr, res.data)
        this.showPreviewModal = true
      })
    },
    onCheckComment() {
      this.isShowCommentList = true
    },
    onDataAnalysis() {
      const url = this.$router.resolve({name: 'DataAnalysis', params: {
        id: this.activityQuestId || ''
      }, query: {
        businessType: 1
      }
      }).href
      window.open(url, '_blank')
    },
    getEntrustList() {
      // 获取调研列表
      this.$axios.post(this.$API.historyDelegationList, {
        sceneId: this.sceneId,
        activityQuestType: this.activityQuestType,
        publishStatus: 1,
        pageSize: 10000
      }).then(res => {
        this.entrustList = res.data?.data || []
        this.activityQuestId = this.entrustList[0]?.activityQuestId
        this.detail = {}
        this.examList = []
        this.onGetDetail()
      }, err => {
        this.$message.error(err.message)
      })
    },
    getDetail() {
      // 获取调研详情
      if (!this.activityQuestId) return
      this.$axios.get(this.$API.getDelegationDetail, { params: {activityQuestId: this.activityQuestId} }).then(res => {
        this.detail = res.data || {}
        this.examList = res.data.examVos || []
      }, err => {
        this.$message.error(err.message)
        this.detail = {}
        this.examList = []
      })
    },
    getCasePracticeDetail() {
      // 获取调研详情
      if (!this.activityQuestId) return
      this.$axios.get(this.$API.getDelegationCasePracticeDetail, { params: {activityQuestId: this.activityQuestId} }).then(res => {
        this.detail = res.data || {}
        this.examList = res.data.exams || []
      }, err => {
        this.$message.error(err.message)
        this.detail = {}
        this.examList = []
      })
    },
    onGetDetail() {
      if (this.activityQuestType === 1) {
        this.getDetail()
      }
      if (this.activityQuestType === 2) {
        this.getCasePracticeDetail()
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.title-content {
  padding: 10px;
  color: #969696;
  background: #f5f5f5;
  font-size: 12px;
  margin-bottom: 10px;
}
.title-line-box {
  display: flex;
  justify-content: space-between;
}
.exam-content {
  height: 500px;
  overflow-y: auto;
}
.data-analysis-box {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.case-practice-box {
  padding-top: 10px;
  .case-label {
    font-weight: 600;
  }
}
.content-box {
  height: 480px;
  overflow-y: scroll;
}
.scene-empty {
  color: rgba(119, 132, 161, 1);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 10px;
  }
}
</style>
